<template>
  <v-dialog
    v-model="showClient"
    scrollable
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.stop="showClient = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Ver Cliente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="edit_mode = !edit_mode">
              {{ edit_mode ? 'Cancelar' : 'Editar Cliente' }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-container v-if="!loading" class="mt-4">
          <v-form ref="clientForm" v-model="valid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="client.name"
                  :rules="clientRules.name"
                  label="Nombre"
                  outlined
                  dense
                  :readonly="!edit_mode"
                  required
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.ruc"
                  :rules="clientRules.ruc"
                  counter="11"
                  label="RUC"
                  outlined
                  :readonly="!edit_mode"
                  required
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.dni"
                  counter="8"
                  :rules="clientRules.dni"
                  label="DNI"
                  outlined
                  :readonly="!edit_mode"
                  required
                  dense
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="client.name_aux"
                  :rules="clientRules.razon_social"
                  label="Razon social (opcional)"
                  outlined
                  :readonly="!edit_mode"
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  v-model="client.type_bank"
                  label="Banco (opcional)"
                  :readonly="!edit_mode"
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.bank_account"
                  label="N° de cuenta (opcional)"
                  outlined
                  dense
                  :readonly="!edit_mode"
                />
              </v-col>
              <v-col cols="12">
                <v-form ref="addressForm" v-model="addressValid">
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="new_address.value"
                        :readonly="!edit_mode"
                        :rules="[v => !!v || 'Escriba una dirección']"
                        dense
                        outlined
                        label="Dirección"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="ubigeo.departamento"
                        :items="departamentos"
                        return-object
                        label="Departamento"
                        :rules="[v => !!v || 'Seleccione el Departamento']"
                        item-text="nombre_ubigeo"
                        item-value="id_ubigeo"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="ubigeo.provincia"
                        :items="provincias[ubigeo.departamento.id_ubigeo]"
                        label="Provincia"
                        return-object
                        :rules="[v => !!v || 'Seleccione la provincia']"
                        item-text="nombre_ubigeo"
                        item-value="id_ubigeo"
                        dense
                        no-data-text="Primero seleccione un departamento"
                        :readonly="!ubigeo.departamento"
                        ref="provincia"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="ubigeo.distrito"
                        :items="distritos[ubigeo.provincia.id_ubigeo]"
                        label="Distrito"
                        return-object
                        :rules="[v => !!v || 'Seleccione el distrito']"
                        item-text="nombre_ubigeo"
                        item-value="id_ubigeo"
                        dense
                        no-data-text="Primero seleccione una provincia"
                        :readonly="!ubigeo.provincia && !ubigeo.departamento"
                        ref="distrito"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-btn
                        color="secondary"
                        @click="addAddress"
                        block
                        medium
                        dense
                        :disabled="!edit_mode"
                      >
                        <span>Agregar</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>

              <v-col cols="12" md="12">
                <v-data-table
                  :headers="address_headers"
                  :items="client.addresses"
                  item-key="id"
                  class="elevation-3 mb-2"
                  :hide-default-footer="true"
                  dense
                >
                  <template v-slot:item.index="{ index }">
                    {{ index + 1 }}
                  </template>
                  <template v-slot:item.actions="{ item, index }">
                    <v-icon
                      medium
                      color="red darken-1"
                      @click="deleteAddress(item, index)"
                      :disabled="!edit_mode"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="client.phones"
                  label="Teléfono (opcional)"
                  :readonly="!edit_mode"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="client.mails"
                  :rules="clientRules.mail"
                  label="Correo (opcional)"
                  :readonly="!edit_mode"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="client.seller_aux"
                  :items="seller_names"
                  :rules="clientRules.seller_aux"
                  outlined
                  dense
                  :readonly="!edit_mode"
                  label="Vendedor"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-progress-linear
          indeterminate
          color="info"
          class="mb-0"
          v-else
        ></v-progress-linear>
      </v-card-text>
      <v-card-actions v-if="edit_mode">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="showClient = false">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateClient">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import departamentos_json from '../facturation/departamentos.json'
import provincias_json from '../facturation/provincias.json'
import distritos_json from '../facturation/distritos.json'
export default {
  props: {
    value: Boolean,
    client_id: Number
  },
  data: () => ({
    client: {},
    clientRules: {
      name: [v => !!v || 'Ingrese el nombre del ciente'],
      seller_aux: [v => !!v || 'Ingrese un vendedor asociado'],
      phone: [
        v =>
          !v ||
          (v && v.length <= 9) ||
          'El teléfono debe ser menor o igual a 9 caracteres'
      ],
      ruc: [
        v => !v || (v && v.length) === 11 || 'El RUC debe tener 11 caracteres'
      ],
      dni: [
        v => !v || (v && v.length) === 8 || 'El DNI debe tener 8 caracteres'
      ]
    },
    valid: true,
    addressValid: true,
    ubigeo: {
      departamento: '',
      provincia: '',
      distrito: ''
    },
    new_address: {},
    departamentos: departamentos_json,
    provincias: provincias_json,
    distritos: distritos_json,
    seller_names: [],
    loading: true,
    edit_mode: false,
    address_headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: false,
        value: 'index'
      },
      {
        text: 'Dirección',
        sortable: false,

        value: 'value'
      },
      {
        text: 'Departamento',
        sortable: false,
        value: 'departamento'
      },
      {
        text: 'Provincia',
        sortable: false,
        value: 'provincia'
      },
      {
        text: 'Distrito',
        filtereable: false,
        sortable: false,
        value: 'distrito',
        align: 'center'
      },
      {
        text: 'Acciones',
        filtereable: false,
        sortable: false,
        value: 'actions',
        align: 'center'
      }
    ]
  }),
  watch: {
    edit_mode(value) {
      let text_mode
      if (value) text_mode = 'Ya puedes editar al cliente'
      else text_mode = 'Se ha cancelado la edición'
      if (this.showClient) this.$showMessage(text_mode, 'info')
    },
    'ubigeo.departamento': function() {
      this.$refs.distrito.reset()
      this.$refs.provincia.reset()
      this.ubigeo.distrito = ''
      this.ubigeo.provincia = ''
    },
    'ubigeo.provincia': function() {
      this.ubigeo.distrito = ''
      this.$refs.distrito.reset()
    }
  },

  computed: {
    showClient: {
      get() {
        if (this.value === true) {
          console.log('Holi')
          this.getClient()
        }
        return this.value
      },
      set(value) {
        if (!this.loading) this.$refs.clientForm.reset()
        this.client = {}
        this.ubigeo = {
          departamento: '',
          provincia: '',
          distrito: ''
        }
        this.edit_mode = false
        this.$emit('input', value)
      }
    }
  },
  methods: {
    updateClient: async function() {
      if (!this.$refs.clientForm.validate()) {
        return
      }
      console.log(this.client)
      // this.client.phones = this.client.phones.split(',').map(function(value) {
      //   return value.trim()
      // })
      // this.client.mails = this.client.mails.split(',').map(function(value) {
      //   return value.trim()
      // })
      try {
        let response = await this.$makePostRequest(
          `/api/clients/${this.client.id}/update`,
          this.client
        )
        console.log('Update response:', response)
        this.$showMessage('Cliente actualizado correctamente', 'success')
        this.showClient = false
      } catch (error) {
        console.error(error)
        this.$showMessage(error, 'error')
      }
    },
    addAddress: function() {
      // TODO: FIX ERROR "ID_UBIGEO OF NULL" WHEN ADD AN ADDRESS
      if (!this.$refs.addressForm.validate()) {
        return
      }
      this.client.addresses.push({
        value: this.new_address.value,
        departamento: this.ubigeo.departamento.nombre_ubigeo.toLocaleUpperCase(),
        provincia: this.ubigeo.provincia.nombre_ubigeo.toLocaleUpperCase(),
        distrito: this.ubigeo.distrito.nombre_ubigeo.toLocaleUpperCase(),
        tipo: 'LC'
      })

      this.$refs.addressForm.reset()

      this.ubigeo = {
        departamento: '',
        provincia: '',
        distrito: ''
      }

      this.new_address = {}
      console.log('Add', this.ubigeo)
    },
    getClient: async function() {
      this.loading = true

      let response = await this.$makeGetRequest(
        `/api/clients/${this.client_id}/get`
      )
      this.loading = false
      this.client = response.data.data
      this.seller_names = this.$sellers.slice()
      this.seller_names.push(this.client.seller_aux)
      // this.client.seller_aux = this.client.seller
      console.log('Hey', this.client)
    },
    deleteAddress: function(item, index) {
      this.client.addresses.splice(index, 1)
    }
  }
}
</script>
