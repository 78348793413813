<template>
  <v-dialog v-model="show" max-width="70%" scrollable>
    <v-card>
      <v-card-title>
        <span>Crear nuevo cliente</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="clientForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="client.name"
                  :rules="clientRules.name"
                  label="Nombre"
                  required
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.ruc"
                  :rules="clientRules.ruc"
                  counter="11"
                  label="RUC"
                  required
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.dni"
                  counter="8"
                  :rules="clientRules.dni"
                  label="DNI"
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="client.name_aux"
                  :rules="clientRules.razon_social"
                  label="Razon social (opcional)"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.type_bank"
                  label="Banco (opcional)"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="client.bank_account"
                  label="N° de cuenta (opcional)"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="client.address"
                  :rules="[v => !!v || 'Escriba una dirección']"
                  label="Dirección"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="ubigeo.departamento"
                  :items="departamentos"
                  return-object
                  label="Departamento"
                  :rules="[v => !!v || 'Seleccione el Departamento']"
                  item-text="nombre_ubigeo"
                  item-value="id_ubigeo"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="ubigeo.provincia"
                  :items="provincias[ubigeo.departamento.id_ubigeo]"
                  label="Provincia"
                  return-object
                  :rules="[v => !!v || 'Seleccione la provincia']"
                  item-text="nombre_ubigeo"
                  item-value="id_ubigeo"
                  no-data-text="Primero seleccione un departamento"
                  :readonly="!ubigeo.departamento"
                  ref="provincia"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="ubigeo.distrito"
                  :items="distritos[ubigeo.provincia.id_ubigeo]"
                  label="Distrito"
                  return-object
                  :rules="[v => !!v || 'Seleccione el distrito']"
                  item-text="nombre_ubigeo"
                  item-value="id_ubigeo"
                  no-data-text="Primero seleccione una provincia"
                  :readonly="!ubigeo.provincia && !ubigeo.departamento"
                  ref="distrito"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="client.phone"
                  :rules="clientRules.phone"
                  label="Teléfono (opcional)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="client.mail"
                  :rules="clientRules.mail"
                  label="Correo (opcional)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="client.seller_aux"
                  :items="[
                    'YUNE VARGAS',
                    'JAVIER BALBUENA',
                    'OFICINA',
                    'DRA ROXANA'
                  ]"
                  :rules="clientRules.seller_aux"
                  label="Vendedor"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="show = false">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="storeClient">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import departamentos_json from '../facturation/departamentos.json'
import provincias_json from '../facturation/provincias.json'
import distritos_json from '../facturation/distritos.json'

export default {
  data: () => ({
    client: {},
    clientRules: {
      name: [v => !!v || 'Ingrese el nombre del ciente'],
      seller_aux: [v => !!v || 'Ingrese un vendedor asociado'],
      phone: [
        v =>
          (v && v.length <= 9) ||
          'El teléfono debe ser menor o igual a 9 caracteres'
      ],
      ruc: [
        v => !v || (v && v.length) === 11 || 'El RUC debe tener 11 caracteres'
      ],
      dni: [
        v => !v || (v && v.length) === 8 || 'El DNI debe tener 8 caracteres'
      ]
    },
    valid: true,
    ubigeo: {
      departamento: '',
      provincia: '',
      distrito: ''
    },
    departamentos: departamentos_json,
    provincias: provincias_json,
    distritos: distritos_json
  }),
  props: {
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$refs.clientForm.reset()
        this.client = {}
        this.ubigeo = {
          departamento: '',
          provincia: '',
          distrito: ''
        }

        this.$emit('input', value)
      }
    }
  },
  watch: {
    'ubigeo.departamento': function() {
      this.$refs.distrito.reset()
      this.$refs.provincia.reset()
      this.ubigeo.distrito = ''
      this.ubigeo.provincia = ''
    },
    'ubigeo.provincia': function() {
      this.ubigeo.distrito = ''
      this.$refs.distrito.reset()
    }
  },
  methods: {
    storeClient: async function() {
      if (!this.$refs.clientForm.validate()) {
        return
      }

      if (!this.client.dni && !this.client.ruc) {
        this.$showMessage('Ponga al menos un RUC o un DNI', 'error')
        return
      }

      this.client.departamento = this.ubigeo.departamento.nombre_ubigeo.toLocaleUpperCase()
      this.client.provincia = this.ubigeo.provincia.nombre_ubigeo.toLocaleUpperCase()
      this.client.distrito = this.ubigeo.distrito.nombre_ubigeo.toLocaleUpperCase()
      this.client.name = this.client.name.toLocaleUpperCase()
      this.client.name_aux = this.client.name_aux
        ? this.client.name_aux.toLocaleUpperCase()
        : null
      this.client.address = this.client.address.toLocaleUpperCase()
      console.log('Store', this.client)
      try {
        let res = await this.$makePostRequest('/api/clients/store', this.client)
        console.log(res)
        this.show = false
        this.$showMessage('Cliente creado exitosamente', 'success')
      } catch (err) {
        console.log(err.response.data)
        this.$showMessage(err.response.data.message, 'error')
      }
    }
  }
}
</script>
