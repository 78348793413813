<template>
  <div class="home">
    <create-client v-model="crear" v-on:input="dialogClosed" />
    <see-client
      v-model="seeClientDialog"
      v-bind:client_id="selectedClient"
      v-on:input="showDialogClosed"
    />
    <v-container class="my-6">
      <div>
        <v-row>
          <v-col cols="12" md="6">
            <div class="text-h5 text-md-h3">Lista de Clientes</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            align-self="center"
            cols="12"
            md="6"
            class="d-flex justify-md-end"
          >
            <v-btn
              outlined
              elevation="7"
              color="secondary"
              @click="crear = !crear"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="clients"
              item-key="id"
              class="elevation-1"
              :search="search"
              :loading="clientsLoading"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item.address="{ item }">
                {{ item.address }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ $formatDate(item.created_at) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon medium @click="verCliente(item)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import CreateClient from '../components/clients/createClient'
import SeeClient from '../components/clients/seeClient'

export default {
  data: () => ({
    search: '',
    clients: [],
    crear: false,
    selectedProvider: {},
    selectedClient: 0,
    seeClientDialog: false,
    buyOrderDialog: false,
    buyOrderKey: 0,
    clientsLoading: true
  }),
  components: { CreateClient, SeeClient },
  computed: {
    headers() {
      return [
        {
          text: 'RUC',
          value: 'ruc'
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          flirteable: true,
          value: 'name'
        },
        { text: 'Dirección', value: 'address' },
        { text: 'Teléfono', value: 'phone' },
        {
          text: 'Fecha de Creación',
          value: 'created_at'
        },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },
  mounted: function() {
    this.clientsLoading = true

    this.$makeGetRequest('/api/clients').then(response => {
      this.clients = response.data.data
      this.clientsLoading = false
    })
  },
  methods: {
    async dialogClosed() {
      if (this.crear === false) {
        this.clientsLoading = true
        await this.$makeGetRequest('/api/clients').then(response => {
          this.clients = response.data.data
          this.clientsLoading = false
        })
      }
    },

    async showDialogClosed() {
      this.clientsLoading = true
      await this.$makeGetRequest('/api/clients').then(response => {
        this.clients = response.data.data
        this.clientsLoading = false
      })
    },
    buyOrderClosed() {
      if (this.buyOrderDialog === false) {
        console.log('buy order dialog closed')
        // location.reload()
        this.buyOrderKey += 1
      }
    },
    verCliente(item) {
      this.selectedClient = item.id
      console.log(item, this.selectedClient, 'Dialog')
      this.seeClientDialog = true
    },
    nuevaCompra(item) {
      console.log(item)
      this.selectedProvider = item.id
      this.buyOrderDialog = true
    }
  }
}
</script>
